<template>
    <About />

    <!--
    <div class="col-12">
        <About />
    </div>
-->
</template>

<script>
// @ is an alias to /src
import About from '@/components/bootstrapDashboard/About.vue'

export default {
    name: 'AboutView',
    components: {
        About
    }
}
</script>
