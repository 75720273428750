<template>
    <div class="starter-template">
        <h1>About</h1>
        <p class="lead">This is an about view.</p>
    </div>
</template>


<style>

.starter-template {
  padding: 3rem 1.5rem;
  text-align: center;
}
</style>
